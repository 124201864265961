import React from 'react';

import $ from 'jquery'
import _ from 'lodash'

import ChildIndicator from './ChildIndicator';



export default class ChildIndicators extends React.Component {

	constructor(props) {

		super(props);

	}

	handleClick(e) {

		e.preventDefault()

		this.props.setActiveCountry(null);
		this.props.setActiveIndicator(this.props.indicator);

		$('html,body').scrollTop(0);

	}

	render() {

		var count = 0;

		return (

			<div className="child-indicators-wrapper">

				<a href="#" className="close" onClick={this.props.resetActive.bind(this)}>✕</a>

				<div className="child-indicator-prefix"></div>

					<div className="child-indicators">
					{
						_.map(this.props.childIndicators, (childIndicator, index) => {
							count++;
							return <ChildIndicator {...this.props}
									key={childIndicator.id}
									indicator={this.props.indicator}
									childIndicator={childIndicator}
									labelPosition="header"
									count={count} />;
						})
					}
					</div>

					{ this.props.country ? <div className="child-indicators-footer">
						<a href="#" className="button" onClick={this.handleClick.bind(this)}>
							{ `View all ${this.props.indicator.title} Rankings` }
						</a>
					</div> : null }

			</div>

		)
	}

}
