import React from 'react';

import $ from 'jquery';
import _ from 'lodash';

import DataActions from '../actions/DataActions';
import DataStore from '../stores/DataStore';

import SubHeader from './SubHeader';
import IndicatorHeader from './IndicatorHeader';
import Country from './Country';
import Loading from './Loading';

import velocity from 'velocity-animate';
import classNames from 'classnames';
import Infinite from 'react-infinite';
import { VelocityComponent, VelocityTransitionGroup } from 'velocity-react';

export default class App extends React.Component {
  getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  constructor(props) {
    super(props);

    this._rankings = [];

    let version = this.getParameterByName('version')
      ? this.getParameterByName('version')
      : '1.3';

    this.state = {
      activeCountry: null,
      singleCountry: false,
      activeIndicator: null,
      activeVersion: version,
      isLoading: true,
      fixedHeader: false,

      versions: {},
      indicators: {},
      unsortedCountries: {},
      countries: {},
    };
  }

  componentDidMount() {
    DataStore.addChangeListener(this.onChange.bind(this));

    // Load the data
    DataActions.loadData(this.state.activeVersion);

    var _this = this;

    window.addEventListener('scroll', function() {
      var $indexRoot = $(_this.refs.indexRoot);
      var scrollTop = $(window).scrollTop();
      var elementOffset = $indexRoot.offset().top;
      var distance = elementOffset - scrollTop;

      if (distance <= 0) {
        if (!_this.state.fixedHeader)
          _this.setState({
            fixedHeader: true,
          });
      } else {
        if (_this.state.fixedHeader)
          _this.setState({
            fixedHeader: false,
          });
      }
    });
  }

  componentWillUnmount() {
    DataStore.removeChangeListener(this.onChange.bind(this));
  }

  onChange() {
    var data = DataStore.getData();

    var countries = DataStore.calculateRank();

    var countryCount = _.size(countries);

    this.setState({
      versions: data.versions,
      indicators: data.indicators,
      countries: data.countries,
      allCountries: data.allCountries,
      unsortedCountries: data.countries,
      isLoading: data.countries.length > 0 ? false : true,
    });

    // If a hash country code was given, find it and set the country
    if (window.location.hash != '') {
      var countryCode = window.location.hash.substr(1);

      var country = _.find(this.state.countries, {
        isoCode: countryCode.toUpperCase(),
      });

      if (country) {
        this.setActiveCountry(country, true);
      }
    }
  }

  // Passed all the way down to the ParentIndicator; updated when an indicator is clicked
  setActiveIndicator(indicator) {
    this.setState({
      activeIndicator: indicator,
    });
  }

  setActiveCountry(country, single = false) {
    this.setState({
      activeCountry: country,
      singleCountry: single,
    });

    // Set the browser hash if an actual country was provided
    if (country) {
      window.location.hash = country.isoCode;
    }
  }

  setActiveVersion(version) {
    DataActions.loadData(version);

    this.setState({
      activeVersion: version,
    });
  }

  resetActive() {
    this.setState({
      activeVersion: '1.3',
      activeCountry: null,
      singleCountry: false,
      activeIndicator: null,
      countries: this.state.unsortedCountries,
    });
  }

  scrollTo($element = $('html')) {
    velocity($('html'), 'scroll', { duration: 1000, easing: 'easeInOutQuint' });
  }

  getIndicatorInfo(parentId, childId) {
    var parentIndicator = _.find(this.state.indicators, { id: parentId });
    var childIndicator = _.find(parentIndicator.children, { id: childId });

    return childIndicator;
  }

  render() {
    var indexRootClasses = classNames('index-root', {
      fixed: this.state.fixedHeader,
    });

    var indexHeadersClass = classNames('index-headers', {
      'with-child-indicators':
        this.state.activeIndicator && !this.state.activeCountry,
    });

    var indexCountriesClass = classNames(
      'index-countries',
      { 'active-version': this.state.activeVersion },
      { 'active-country': this.state.activeCountry },
      { 'active-indicator': this.state.activeIndicator },
    );

    var countryCount = _.size(this.state.countries);

    var countryHeight = window.innerWidth <= 768 ? 120 : 60;

    var countries = this.state.singleCountry
      ? _.filter(this.state.countries, {
          id: this.state.activeCountry.id.toString(),
        })
      : this.state.countries;

    return (
      <div>
        <SubHeader
          {...this.props}
          versions={this.state.versions}
          countries={this.state.countries}
          allCountries={this.state.allCountries}
          scrollTo={this.scrollTo.bind(this)}
          setActiveVersion={this.setActiveVersion.bind(this)}
          setActiveCountry={this.setActiveCountry.bind(this)}
          setActiveIndicator={this.setActiveIndicator.bind(this)}
          activeVersion={this.state.activeVersion}
          activeIndicator={this.state.activeIndicator}
          activeCountry={this.state.activeCountry}
        />

        <div className={indexRootClasses} ref="indexRoot">
          <div className={indexHeadersClass}>
            <div className="content">
              <div className="rank-header">
                <a href="#" className="roundel">
                  ↓
                </a>
              </div>

              <div className="overall-header">
                <a
                  href="#"
                  className="indicator-title"
                  onClick={this.resetActive.bind(this)}
                >
                  Overall
                </a>
              </div>

              <div className="indicator-headers">
                {_.map(this.state.indicators, (indicator, index) => {
                  return (
                    <IndicatorHeader
                      {...this.props}
                      key={indicator.id}
                      indicator={indicator}
                      scrollTo={this.scrollTo.bind(this)}
                      setActiveCountry={this.setActiveCountry.bind(this)}
                      setActiveIndicator={this.setActiveIndicator.bind(this)}
                      resetActive={this.resetActive.bind(this)}
                      getIndicatorInfo={this.getIndicatorInfo.bind(this)}
                      activeIndicator={this.state.activeIndicator}
                      activeCountry={this.state.activeCountry}
                    />
                  );
                })}
              </div>
            </div>
          </div>

          {this.state.isLoading ? <Loading {...this.props} /> : null}

          <div className={indexCountriesClass}>
            <Infinite
              key="infinite"
              elementHeight={countryHeight}
              infiniteLoadBeginEdgeOffset={200}
              useWindowAsScrollContainer={true}
            >
              {_.map(countries, (country, index) => {
                return (
                  <Country
                    {...this.props}
                    key={country.id}
                    country={country}
                    index={index}
                    countryCount={countryCount}
                    height={countryHeight}
                    scrollTo={this.scrollTo.bind(this)}
                    setActiveCountry={this.setActiveCountry.bind(this)}
                    setActiveIndicator={this.setActiveIndicator.bind(this)}
                    resetActive={this.resetActive.bind(this)}
                    getIndicatorInfo={this.getIndicatorInfo.bind(this)}
                    activeIndicator={this.state.activeIndicator}
                    activeCountry={this.state.activeCountry}
                    singleCountry={this.state.singleCountry}
                  />
                );
              })}
            </Infinite>
          </div>
        </div>
      </div>
    );
  }
}
