import $ from "jquery";
import Flickity from "flickity";
import "jquery-bridget";

export default function() {
  $.bridget("flickity", Flickity);

  var $tweets = $(".tweets");

  $tweets.flickity({
    cellSelector: ".tweet",
    cellAlign: "center",
    percentPosition: false,
    contain: true,
    arrowShape: {
      x0: 30,
      x1: 60,
      y1: 30,
      x2: 65,
      y2: 25,
      x3: 40
    }
  });
}
