import React from 'react';

import $ from 'jquery'
import _ from 'lodash'

import classNames from 'classnames';



export default class VersionFilter extends React.Component {

	constructor(props) {

		super(props);

		this.state = {
			selected: props.activeVersion ? {
				value: props.activeVersion,
				label: 'Version ' + props.activeVersion
			} : null
		}

	}

	componentWillReceiveProps(nextProps) {

		this.setState({
			selected: nextProps.activeVersion ? {
				value: nextProps.activeVersion,
				label: 'Version ' + nextProps.activeVersion
			} : null
		})

	}

	handleChange() {

		let versionSelect = $(this.refs.versionSelect)
		let version = versionSelect.val()

		this.props.setActiveVersion(version);
		this.props.setActiveCountry(null);
		this.props.setActiveIndicator(null);

	}

	render() {

		const options = _.reduce(this.props.versions, function(result, y, key){
				result[key] = { value: y.title, label: y.title };
				return result;
			}, {});

	    let defaultOption = this.state.selected;

		return (
			<select className="version" onChange={this.handleChange.bind(this)} value={defaultOption.value} ref="versionSelect">
				{
					_.map(_.toArray(options), (version, index) => {
						return <option key={index} value={version.value}>Version {version.label}</option>
					})
				}
			</select>
		)

	}

}
