import React from 'react';

import $ from 'jquery';
import _ from 'lodash';

import classNames from 'classnames';
import velocity from 'velocity-animate';
import Tippy from 'tippy.js';

export default class ChildIndicator extends React.Component {
  constructor(props) {
    super(props);
  }

  willReceiveProps(nextProps) {
    this.setGraphToolTip();
  }

  calculateWidth(rank) {
    var totalWidth = 100;

    return Math.floor(Math.abs(rank - 0.5) * totalWidth);
  }

  calculateBarCss(rank) {
    var midPoint = 50;

    var width = this.calculateWidth(rank);

    if (rank <= 0.5) {
      var left = midPoint;
    } else {
      var left = midPoint - width;
    }

    var style = { left: left + 'px', width: width + 'px' };

    return style;
  }

  componentWillMount() {
    // Get the indicator description
    if (this.props.indicator) {
      this.indicatorInfo = this.props.getIndicatorInfo(
        this.props.indicator.id,
        this.props.childIndicator.id,
      );
    }
  }

  render() {
    var barStyle = this.calculateBarCss(this.props.childIndicator.rank);

    var childIndicatorClass = classNames(
      'child-indicator',
      { disabled: this.props.childIndicator.rank == 0 },
      {
        min:
          this.props.childIndicator.rank > 0 &&
          this.calculateWidth(this.props.childIndicator.rank) >= 50 - 11 &&
          this.props.childIndicator.rank >= 0.5,
      },
      {
        max:
          this.props.childIndicator.rank > 0 &&
          this.calculateWidth(this.props.childIndicator.rank) >= 50 - 11 &&
          this.props.childIndicator.rank < 0.5,
      },
    );

    var childIndicatorLabel = (
      <div className={`child-indicator-${this.props.labelPosition}`}>
        <div className="child-indicator-label">
          {this.indicatorInfo && (
            <span
              className="tooltip"
              ref="label"
              title={this.indicatorInfo.description}
            >
              {this.props.childIndicator.title}
            </span>
          )}
        </div>
      </div>
    );

    return (
      <div className={childIndicatorClass}>
        {this.props.labelPosition == 'header' ? childIndicatorLabel : null}

        {this.props.childIndicator.rank ? (
          <div className="child-indicator-graph-wrapper">
            <div
              ref="graph"
              className="child-indicator-graph"
              data-rank={this.props.childIndicator.rank}
            >
              <div className="base">
                <div className="mid" />
                {this.props.childIndicator.rank > 0 ? (
                  <div className="bar" ref="bar" style={barStyle} />
                ) : null}
              </div>
            </div>
          </div>
        ) : null}

        {this.props.labelPosition == 'footer' ? childIndicatorLabel : null}
      </div>
    );
  }
}
