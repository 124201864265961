import Dispatcher from '../dispatcher/Dispatcher';
import WebAPI from '../util/WebAPI';
import { ActionTypes } from '../constants/Constants';

export default {
  loadData(version) {
    //console.log("Loading data for " + version);

    Dispatcher.dispatch({
      type: ActionTypes.LOAD_DATA,
      version: version,
    });

    WebAPI.loadData(version);
  },

  receiveData(data, errors) {
    //console.log("Received data");

    Dispatcher.dispatch({
      type: ActionTypes.RECEIVE_DATA,
      data: data,
      errors: errors,
    });
  },
};
