import React from 'react';

import $ from 'jquery'
import _ from 'lodash'

import ParentIndicators from './ParentIndicators';
import ParentIndicator from './ParentIndicator';
import ChildIndicator from './ChildIndicator';
import ChildIndicators from './ChildIndicators';
import AllChildIndicators from './AllChildIndicators';

import velocity from 'velocity-animate';
import { VelocityComponent, VelocityTransitionGroup } from 'velocity-react';
import classNames from 'classnames';



export default class Country extends React.Component {

	constructor(props) {

		super(props);

		this.state = {
			isActive: (props.activeCountry && props.activeCountry.id == props.country.id) ? true : false,
			isCountryActive: (props.activeCountry) ? true : false,
			isIndicatorActive: (props.activeIndicator) ? true : false,
			height: props.singleCountry ? (680 + props.height) : props.height
		}

	}

	componentWillReceiveProps(nextProps) {

		var isActive = (nextProps.activeCountry && nextProps.activeCountry.id == nextProps.country.id) ? true : false;
		var isCountryActive = (nextProps.activeCountry) ? true : false;
		var isIndicatorActive = (nextProps.activeIndicator) ? true : false;

		// If country is active only
		var height = (isActive) ? (680 + this.props.height) : this.props.height;

		// If country is active, and an indicator is active
		var height = (isActive && isCountryActive && isIndicatorActive) ? (240 + this.props.height) : height;

		// If country is active, and an indicator is active, on small screen
		if ( window.innerWidth <= 768 )
			var height = (isActive && isCountryActive && isIndicatorActive) ? (150 + this.props.height) : height;

		this.setState({
			isActive: isActive,
			isCountryActive: isCountryActive,
			isIndicatorActive: isIndicatorActive,
			height: height
		})

	}

	handleClick(e) {

		e.preventDefault()

		if (!this.state.isActive) { // if the country isn't active

			this.props.setActiveCountry(this.props.country, false);
			this.props.setActiveIndicator(null);

		} else if (this.state.isActive && this.props.activeIndicator) { // if the country is active, and there's an active indicator

			this.props.setActiveIndicator(null);

		} else { // if the country is active, and no indicator selected

			this.props.setActiveCountry(null, false);
			this.props.setActiveIndicator(null);

		}

	}

	getActiveChildIndicators() {

		var ret,
			_this = this;

		var ret = _.find(this.props.country.indicators, function(i, index){
			return _this.props.activeIndicator.id == index;
		});

		return ret.children;

	}

	render() {

		var countryClass = classNames(
				'index-country',
				{ active: this.state.isActive },
				{ even: (this.props.index % 2) },
				{ odd: !(this.props.index % 2) }
			);

		var childIndicatorsClass = classNames(
				'child-indicators',
				( this.props.activeIndicator ? this.props.activeIndicator.code : null )
			);

		// Show parent indicators as usual, unless a master one has been selected
		var showParentIndicators = (this.state.isCountryActive && this.state.isIndicatorActive) || // a country and indicator selected
			(!this.state.isCountryActive && !this.state.isIndicatorActive) || // no country or indicator selected
			(this.state.isCountryActive && !this.state.isIndicatorActive) ? // country selected but no indicator
				true : false;

		var showChildIndicators = !showParentIndicators ? true : false;

		var rankClass = classNames(
				'ranks',
				( this.props.activeIndicator ? 'double' : null )
			);

		var indicatorRankClass = classNames(
				'roundel',
				( this.props.activeIndicator ? this.props.activeIndicator.code : null )
			);

		return (

			<VelocityComponent key="country-velocity" animation={{ height: this.state.height, translateZ: 0 }} duration={720} easing="easeInOutQuint">

				<div className={countryClass}>

					<div className="parent-content">

						<div className={rankClass}>
							<div className="roundels">
								<a href="#" className="roundel" onClick={this.handleClick.bind(this)}>{this.props.country.rank}</a>
								{ this.props.activeIndicator ? <a href="#" className={indicatorRankClass} onClick={this.handleClick.bind(this)}>{this.props.country.ranks[this.props.activeIndicator.id]}</a> : null }
							</div>
						</div>

						<div className="name">
							<a href="#" onClick={this.handleClick.bind(this)}>{this.props.country.name}</a>
						</div>

						{ showParentIndicators ? <ParentIndicators {...this.props} indicators={this.props.country.indicators} /> : null }

						{ showChildIndicators ? <div className={childIndicatorsClass}>

								{
									_.map(this.getActiveChildIndicators(), (childIndicator, index) => {
										return <ChildIndicator {...this.props}
											key={index}
											country={this.props.country}
											childIndicator={childIndicator}
											labelPosition="none" />;
									})
								}

							</div> : null}

						<VelocityTransitionGroup enter={{animation: "fadeIn"}} leave={{animation: "fadeOut"}}>
						{ (this.state.isActive && !this.props.activeIndicator) ? <AllChildIndicators {...this.props} country={this.props.country} /> : null }
						</VelocityTransitionGroup>

					</div>

				</div>

			</VelocityComponent>

		)

	}

}
