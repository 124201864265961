import React from 'react';

import $ from 'jquery'
import _ from 'lodash'

import ChildIndicator from './ChildIndicator';

import classNames from 'classnames';

export default class AllChildIndicators extends React.Component {

	constructor(props) {

		super(props);

	}

	render() {

		return (

			<div className="all-child-indicators-wrapper">

				<div className="child-indicator-prefix"></div>

				<div className="all-child-indicators">

					{
						_.map(this.props.country.indicators, (indicator, index) => {

							var parentIndicatorClass = classNames(
									'parent-indicator',
									indicator.code
								);

							return <div key={`pI-${index}`} className={parentIndicatorClass} data-indicator-title={indicator.title}>
							{
								_.map(indicator.children, (childIndicator, childIndex) => {
									return <ChildIndicator {...this.props}
										key={childIndicator.id}
										indicator={indicator}
										childIndicator={childIndicator}
										labelPosition="footer" />;
								})
							}
							</div>
						})
					}

				</div>

			</div>

		)
	}

}
