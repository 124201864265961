// --------------------------  vendor  -------------------------- //

import $ from "jquery";

import MobileDetect from "mobile-detect";
import "turbolinks-animate";
import Turbolinks from "turbolinks";
import "jquery-inview";
import Flickity from "flickity";

import "./lib/modernizr-custom";

import "jquery-ui/ui/widget";
import "jquery-ui/ui/widgets/button";
import "jquery-ui/ui/widgets/dialog";
import "jquery-ui/ui/widgets/datepicker";

import "jquery-ui/ui/data";
import "jquery-ui/ui/disable-selection";
import "jquery-ui/ui/focusable";
import "jquery-ui/ui/form";
import "jquery-ui/ui/ie";
import "jquery-ui/ui/keycode";
import "jquery-ui/ui/labels";
import "jquery-ui/ui/plugin";
import "jquery-ui/ui/safe-active-element";
import "jquery-ui/ui/safe-blur";
import "jquery-ui/ui/scroll-parent";
import "jquery-ui/ui/tabbable";
import "jquery-ui/ui/unique-id";
import "jquery-ui/ui/version";
import "jquery-ui/ui/position";

import Velocity from "velocity-animate";
import "jquery.scrollTo";
import "jquery-bridget";

// --------------------------  make jquery available  -------------------------- //

import A11yDialog from "a11y-dialog";
import imagesLoaded from "imagesloaded";
import Packery from "packery";
import Fitvids from "fitvids";
import "parsleyjs";
import Tippy from "tippy.js";
import Stickybits from "stickybits";
import "./lib/fresco";

import "jquery-bridget";

imagesLoaded.makeJQueryPlugin($);
$.bridget("packery", Packery);

import header from "./modules/header";
import index from "./modules/index";
import navigation from "./modules/navigation";
import tweets from "./modules/tweets";

// --------------------------  detect mobile  -------------------------- //

const md = new MobileDetect(navigator.userAgent);
const grade = md.mobileGrade();

Modernizr.addTest({
  mobile: !!md.mobile(),
  phone: !!md.phone(),
  tablet: !!md.tablet(),
  mobilegradea: grade === "A"
});

// --------------------------  init  -------------------------- //

let GoodCountry = {
  go() {
    // --------------------------  kick off modules first  -------------------------- //
    header();
    index();
    navigation();
    tweets();

    let $body = $("body");

    // Override jquery's fadein and fadeout
    $.fn.fadeIn = function(duration, complete) {
      Velocity(this, { opacity: 1, duration, complete }, { display: "block" });
      return $(this);
    };

    $.fn.fadeOut = function(duration, complete) {
      Velocity(this, { opacity: 0, duration, complete }, { display: "none" });
      return $(this);
    };

    let isSmallScreen = () => ($(window).width() <= 768 ? true : false);

    let isMobile = function() {
      let browser = navigator.userAgent || navigator.vendor || window.opera;

      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          browser
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|slidescell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          browser.substr(0, 4)
        )
      ) {
        return true;
      }

      return false;
    };

    // ADD MOBILE CLASS
    if (isMobile()) {
      $body.addClass("mobile");
    } else {
      $body.addClass("not-mobile");
    }

    // ADD ACTIVE CLASS
    $body.addClass("active");

    // OPEN LINKS IN NEW WINDOW
    $("a:not([href^=mailto])").each(function() {
      if (this.href.indexOf(location.hostname) === -1) {
        return $(this).attr("target", "_blank");
      }
    });

    // FITVIDS

    Fitvids($(".fitvids"));

    // HANDLE MESSSAGES

    let $message = $(".message");

    let closeMessage = () =>
      Velocity(
        $message[0],
        {
          opacity: 0
        },
        {
          display: "none",
          duration: 240
        }
      );

    $message.find("a.close").on("click", function() {
      closeMessage();
      return false;
    });

    $(document).on("keyup", function(e) {
      if (e.keyCode === 27) {
        return closeMessage();
      }
    });

    // ON SCROLL, MOVE PATTERN

    $(window).on("scroll", function() {
      let distance = $(document).scrollTop();
      let target = distance * 0.2;
      return $(".pattern").css({
        top: target + "px"
      });
    });

    // ON CLICK SCROLL INDICATOR, SCROLL

    $("a.scroll-indicator").on("click", function(e) {
      let target = $(this).attr("href");
      let $target = $(target);

      Velocity(document.body, "scroll", {
        duration: 750,
        offset: $target.offset().top,
        easing: "easeInOutSine"
      });

      return false;
    });

    // VARIOUS BLOCK STRUCTURES

    // -- page blocks
    let $pageBlocks = $(".page-blocks");
    let $pageBlocksBlocks = $pageBlocks.find(".page-block");

    $pageBlocks.packery({
      itemSelector: ".page-block"
    });

    $pageBlocksBlocks.each(function() {
      let $block = $(this);
      return $block.imagesLoaded(e => {
        $block.addClass("loaded");
        $pageBlocks.packery({
          itemSelector: ".page-block"
        });
        setPositionClasses($pageBlocks);
        return;
      });
    });

    const setPositionClasses = $pageBlocks => {
      // Check what side the item is one
      return $pageBlocksBlocks.each(function() {
        if ($(this).position().left === 0 && !$(this).hasClass("left")) {
          $(this).removeClass("right");
          return $(this).addClass("left");
        } else if ($(this).position().left > 0 && !$(this).hasClass("right")) {
          $(this).removeClass("left");
          return $(this).addClass("right");
        }
      });
    };

    // -- content grid
    let $contentGrid = $(".content-grid");
    let $contentGridBlocks = $contentGrid.find(".block");

    $contentGridBlocks.each(function() {
      let $block = $(this);
      return $block.imagesLoaded(e => {
        $block.addClass("loaded");
        return $contentGrid.packery({
          itemSelector: ".block"
        });
      });
    });

    // -- Apply active class as user scrolls down

    $pageBlocksBlocks.on("inview", function(event, isInView) {
      let $block = $(this);
      if (isInView) {
        return $block.addClass("active");
      }
    });

    // EQUALIZE BLOCK GRID

    let blockGrid = $(".block-grid");
    let blockGridItems = $(".block .content", blockGrid);

    let equalizeBlockGrid = function() {
      let currentTallest = 0;
      let currentRowStart = 0;
      let rowItems = [];
      let topPosition = 0;

      return blockGridItems.each(function(i) {
        let $el = $(this);
        topPosition = $el.position().top - blockGrid.position().top;

        return $el.imagesLoaded(function() {
          $el.addClass("loaded");

          if ($(window).width() >= 568) {
            if (currentRowStart !== topPosition) {
              // we just came to a new row.  Set all the heights on the completed row
              //for currentItem in [0..rowItems.length-1]
              //rowItems[currentItem].height(currentTallest)

              rowItems.length = 0; // empty the array
              currentRowStart = topPosition;
              currentTallest = $el.outerHeight();
              rowItems.push($el);
            } else {
              // another li on the current row.  Add it to the list and check if it's taller
              rowItems.push($el);
              if (currentTallest < $el.outerHeight()) {
                currentTallest = $el.outerHeight();
              }
            }

            // do the last row
            return __range__(0, rowItems.length - 1, true).map(currentItem =>
              rowItems[currentItem].outerHeight(currentTallest)
            );
          }
        });
      });
    };

    equalizeBlockGrid();

    $(window).on("resize", function() {
      blockGridItems.css({
        height: "auto"
      });
      return equalizeBlockGrid();
    });

    // START CYCLE AND EQUALIZE HOMEPAGE FEATURES TEXT

    let $homeFeaturesWrappers = $(".home-features");
    let $homeFeatures = $homeFeaturesWrappers.find(".feature");
    let $homeFeaturesText = $homeFeatures.find(".text");
    let $homeFeaturesImage = $homeFeatures.find(".image");

    $homeFeaturesImage.each(function() {
      let $image = $(this).find("a");
      if ($image.find("img").length > 1) {
        $image.flickity({
          cellSelector: "img",
          autoPlay: true,
          pageDots: false,
          prevNextButtons: false,
          wrapAround: true,
          duration: 3000,
          selectedAttraction: 0.01,
          friction: 0.15
        });
      }
    });

    // MAKE SHARE WIDGET STICKY

    Stickybits(".share-widget", { stickyBitStickyOffset: 140 });

    // INIT CYCLE for PAGE SLIDESHOWS

    let $entrySlideshow = $(".entry .images");

    if ($entrySlideshow.find(".image").length > 1) {
      /*
      $entrySlideshow.cycle({
        slides: '> .image',
        autoHeight: '1140:855',
        speed: 800,
        timeout: 5000,
        log: false,
      });
      */
    }

    // OPEN DIALOG BOX ON EMAIL SUBMIT

    let $emailUpdatesForm = $("#email-updates-form");

    $emailUpdatesForm.on("submit", function(ev) {
      ev.preventDefault();

      let email = $emailUpdatesForm.find("input.email-input").val();

      if (email !== "") {
        let dialogEl = document.getElementById("email-dialog");
        let dialog = new A11yDialog(dialogEl);

        $("#email-dialog")
          .find("#fields_email")
          .val(email);

        setTimeout(
          () =>
            $("#email-dialog")
              .find("#fields_fname")
              .focus(),
          150
        );

        dialog.show();
      }

      return false;
    });

    // FORM VALIDATION

    $("form.validate").parsley();

    // TOOLTIPS

    if (!isMobile()) {
      Tippy(".tooltip", {
        animation: "fade",
        animateFill: false,
        arrow: true
      });
    }

    // VOTE FUNCTIONALITY

    let $voteButtons = $("a.vote:not(.disabled)");

    $voteButtons.on("click", function(ev) {
      let $voteButton = $(this);

      ev.preventDefault();

      if (window.confirm("Are you sure you want to vote for this candidate?")) {
        $voteButton.addClass("disabled");
        return (location.href = $voteButton.attr("href"));
      }
    });

    // REVEALABLE CONTENT

    let $revealableContent = $(".revealable.content");

    $(".reveal-content").on("click", function() {
      $revealableContent.fadeIn();
      $(this).addClass("disabled");
      $(".entry").velocity("scroll", {
        offset: $("header").outerHeight() * -1,
        duration: 750,
        easing: "easeInOutSine"
      });
      return false;
    });

    // SCROLL LINKS
    return $("a.scroll").on("click", function() {
      let target = $(this).data("target");
      let $target = $(target);
      if ($target) {
        return $target.velocity("scroll", {
          offset: $("header").outerHeight() * -1,
          duration: 750,
          easing: "easeInOutSine"
        });
      }
    });
  }
};

$(document).ready(() => {
  $("body").addClass("ready");

  // Kick everything off
  GoodCountry.go();

  // --------------------------  initialise turbolinks  -------------------------- //

  Turbolinks.start();

  $(document).on("turbolinks:request-start", () => {});

  $(document).on("turbolinks:load", () => {
    TurbolinksAnimate.init({
      animation: "fadeIn",
      element: document.querySelector("body"),
      duration: ".25s"
    });

    GoodCountry.go();
  });
});

function __range__(left, right, inclusive) {
  let range = [];
  let ascending = left < right;
  let end = !inclusive ? right : ascending ? right + 1 : right - 1;
  for (let i = left; ascending ? i < end : i > end; ascending ? i++ : i--) {
    range.push(i);
  }
  return range;
}
