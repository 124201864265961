import React from 'react';

import $ from 'jquery'
import _ from 'lodash'

import ChildIndicators from './ChildIndicators';

import classNames from 'classnames';



export default class ParentIndicator extends React.Component {

	constructor(props) {

		super(props);

		this.state = {
			isActive: false
		}

	}

	componentWillReceiveProps(nextProps) {

		this.setState({
			isActive: (
				nextProps.activeCountry &&
				nextProps.activeCountry.id == this.props.country.id &&
				nextProps.activeIndicator &&
				nextProps.activeIndicator.id == this.props.indicator.id) ? true : false
		})

	}

	handleClick(e) {

		if (!this.state.isActive) {

			this.props.setActiveCountry(this.props.country)
			this.props.setActiveIndicator(this.props.indicator)

		} else {

			this.props.resetActive()

		}

	}

	getGetOrdinal(n) {
		var s=["th","st","nd","rd"],
		v=n%100;
		return (s[(v-20)%10]||s[v]||s[0]);
	}

	render() {

		var indicatorClass = classNames(
				'indicator',
				this.props.indicator.code,
				{ active: this.state.isActive }
			);

		var valueClass = classNames(
				'value',
				{ first: this.props.indicator.rank == 1 }
			);

		var barWidth = Math.floor( ( 1 - ( this.props.indicator.rank / this.props.countryCount ) ) * 100 );

		var barStyle = {
			width: barWidth + '%'
		};

		return (

			<div className={indicatorClass}>

				<div className="indicator-label">
					{this.props.indicator.title}
				</div>

				<div className="indicator-graph" onClick={this.handleClick.bind(this)}>
					<div className={valueClass}>
						{this.props.indicator.rank}
						<span className="ordinal">{this.getGetOrdinal(this.props.indicator.rank)}</span>
						<span className="count"> of {this.props.countryCount}</span>
					</div>
					<div className="base">
						<div className="bar" style={barStyle}></div>
					</div>
				</div>

				{ this.state.isActive ? <ChildIndicators {...this.props}
					country={this.props.country}
					indicator={this.props.indicator}
					childIndicators={this.props.indicator.children} /> : null }

			</div>

		)

	}

}
