import DataActions from '../actions/DataActions';
import request from 'superagent';

export default {
  loadData(version) {
    let url = `/assets/static/index-data/indexData${version}.json?v=201904`;

    request
      .get(url)
      .set('Accept', 'application/json')
      .end(function(error, res) {
        if (res.error) {
          // TODO
        } else {
          var json = JSON.parse(res.text);
          DataActions.receiveData(json);
        }
      });
  },
};
