import React from 'react';

import $ from 'jquery';
import _ from 'lodash';

import classNames from 'classnames';

import velocity from 'velocity-animate';
import Tippy from 'tippy.js';

export default class CountryFilter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: props.activeCountry
        ? {
            value: props.activeCountry.id,
            label: props.activeCountry.name,
          }
        : null,
    };
  }

  handleChange() {
    let countrySelect = $(this.refs.countrySelect);
    let countryId = countrySelect.val();

    // Create tooltip
    this.refs.countrySelect.setAttribute(
      'title',
      'Unfortunately the UN and other organisations don’t publish enough data on this country for us to calculate its ranking. <a href="/index/your-questions/countries-included/">Follow this link to find out more.</a>',
    );
    const tip = Tippy.one(this.refs.countrySelect, {
      delay: 0,
      placement: 'bottom',
      interactive: true,
      animation: 'fade',
      allowTitleHTML: true,
      animateFill: false,
      arrow: true,
    });

    tip.hide();

    if (countryId != 'all') {
      let country = _.find(this.props.countries, { id: countryId });

      if (country == null) {
        tip.show();
      } else {
        this.props.setActiveCountry(country, true);
        this.props.setActiveIndicator(null);
      }
    } else {
      this.props.setActiveCountry(null);
      this.props.setActiveIndicator(null);
    }

    // Hide tooltips when the select is blurred
    countrySelect.on('blur', function() {});
  }

  render() {
    var alphaCountries = _.sortBy(this.props.allCountries, 'name');

    const options = _.reduce(
      alphaCountries,
      function(result, c, key) {
        result[key] = {
          value: c.id,
          label: c.name,
          hasIndicators: 'indicators' in c ? null : '[no data - why?]',
        };
        return result;
      },
      {},
    );

    let defaultOption = this.state.selected ? this.state.selected.value : null;

    return (
      <select
        className="country"
        onChange={this.handleChange.bind(this)}
        value={defaultOption}
        ref="countrySelect"
      >
        <option value="all">Jump to a country...</option>
        {_.map(_.toArray(options), (country, index) => {
          return (
            <option
              key={index}
              value={country.value}
              data-has-indicators={country.hasIndicators ? false : true}
            >
              {country.label} {country.hasIndicators}
            </option>
          );
        })}
      </select>
    );
  }
}
