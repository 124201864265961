import $ from "jquery";
import React from "react";
import ReactDOM from "react-dom";

import App from "../react/components/App";

export default function() {
  var $theIndex = $("#the-index");

  if ($theIndex.length > 0) {
    ReactDOM.render(
      <App indexUrl={url} />,
      document.getElementById("the-index")
    );
  }
}
