import React from 'react';

import $ from 'jquery';
import _ from 'lodash';

import Tippy from 'tippy.js';

import ChildIndicators from './ChildIndicators';

import classNames from 'classnames';

export default class IndicatorHeader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isActive:
        !props.activeCountry &&
        props.activeIndicator &&
        props.activeIndicator.id == props.indicator.id
          ? true
          : false,
      isCountryActive: props.activeCountry ? true : false,
    };
  }

  componentDidMount() {
    if (window.innerWidth <= 568) {
      // Create tooltip
      Tippy(this.refs.header, {
        delay: 240,
        placement: 'bottom',
        animation: 'fade',
        animateFill: false,
        arrow: true,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      isActive:
        !nextProps.activeCountry &&
        nextProps.activeIndicator &&
        nextProps.activeIndicator.id == this.props.indicator.id
          ? true
          : false,
      isCountryActive: nextProps.activeCountry ? true : false,
    });
  }

  handleClick(e) {
    e.preventDefault();

    if (window.innerWidth > 568) {
      if (!this.state.isActive) {
        this.props.setActiveCountry(null);
        this.props.setActiveIndicator(this.props.indicator);
      } else {
        this.props.resetActive();
      }
    }
  }

  render() {
    var indicatorHeaderClass = classNames(
      'indicator-header-wrapper',
      this.props.indicator.code,
      { active: this.state.isActive },
    );

    var str = this.props.indicator.title;
    var matches = str.match(/\b(\w)/g);
    var acronym = matches.join('');

    return (
      <div className={indicatorHeaderClass}>
        <div className="indicator-header">
          <a
            href="#"
            className="indicator-title"
            ref="header"
            title={`Global Contribution to <strong>${
              this.props.indicator.title
            }</strong>`}
            onClick={this.handleClick.bind(this)}
          >
            <span className="code">{acronym}</span>
            <span className="main">{this.props.indicator.title}</span>
            <span className="sub">Global Contribution</span>
          </a>
        </div>

        {this.state.isActive && !this.props.activeCountry ? (
          <ChildIndicators
            {...this.props}
            indicator={this.props.indicator}
            childIndicators={this.props.indicator.children}
          />
        ) : null}
      </div>
    );
  }
}
