import $ from "jquery";
import Velocity from "velocity-animate";

export default function() {
  // -- desktop navigation

  if ($(window).width() > 768) {
    let nav = $(".navbar-nav");
    let subNavParents = nav.find(".has-children");

    let openSubNav = $child => {
      const childElement = $child[0];

      if (childElement) {
        $child.addClass("show");

        Velocity(childElement, "stop");

        Velocity(
          childElement,
          {
            translateY: "-5px",
            opacity: 1
          },
          {
            display: "block",
            duration: 250
          }
        );
      }
    };

    let closeSubNav = $child => {
      const childElement = $child[0];

      if (childElement) {
        $child.removeClass("show");

        Velocity(childElement, "stop");

        Velocity(
          childElement,
          {
            translateY: "0",
            opacity: 0
          },
          {
            display: "none",
            duration: 250
          }
        );
      }
    };

    let setSubNavPosition = (subNav, subNavParent) =>
      subNav
        .css({
          top: "auto",
          left: "auto",
          position: "absolute",
          display: "block"
        })
        .position({
          my: "top-20",
          at: "bottom",
          of: subNavParent
        })
        .css({
          display: "none"
        });

    // -- on resize and on font load we need to position sub navs again
    let setSubNavPositions = function() {
      return subNavParents.each(function() {
        let subNavParent = $(this);
        let subNav = subNavParent.find("ul.nav-sub");
        return setSubNavPosition(subNav, subNavParent);
      });
    };

    // -- init hover for sub navs
    let initSubNavBehaviour = () =>
      subNavParents.each(function() {
        let $subNavParent = $(this);
        let $subNav = $subNavParent.find("ul.nav-sub");

        if ($subNav) {
          setSubNavPosition($subNav, $subNavParent);
          $subNavParent
            .on("mouseenter", () => openSubNav($subNav))
            .on("mouseleave", () => closeSubNav($subNav));

          return $subNavParent.find("a").off(
            "click",
            $subNavParent.find("a").on("click", () => closeSubNav($subNav))
          );
        }
      });

    initSubNavBehaviour();

    $(window).on("resize", () => initSubNavBehaviour());

    var wfActiveInterval = setInterval(() => {
      if ($("html").hasClass("wf-opensansregular-n4-active")) {
        setSubNavPositions();
        return clearInterval(wfActiveInterval);
      }
    }, 150);

    $(window).on("resize", function() {
      return setSubNavPositions();
    });
  }

  // -- mobile navigation

  var $header = $("header");
  var $navToggle = $("button.nav-toggle");
  var $nav = $(".navbar");
  var $navItems = $nav.find("ul.nav > li.nav-item");
  var $navLinks = $nav.find("ul.nav > li.nav-item > a");

  $navToggle.on("click", function() {
    if ($nav.hasClass("active")) {
      $nav.removeClass("active");
      $header.removeClass("visibility-really-pinned");
    } else {
      $nav.addClass("active");
      $header.addClass("visibility-really-pinned");
    }

    return false;
  });

  var openSubNav = function($item) {
    closeAllSubNavs();
    $item.addClass("active");
  };

  var closeSubNav = function($item) {
    $item.removeClass("active");
  };

  var closeAllSubNavs = function() {
    $navItems.each(function() {
      closeSubNav($(this));
    });
  };

  $navLinks.on("click", function(event) {
    var $target = $(event.target);
    var $link = $(this);
    var $item = $link.parent();

    if ($target.is("span")) {
      if ($item.hasClass("active")) {
        closeSubNav($item);
      } else {
        openSubNav($item);
      }

      return false;
    }
  });
}
