import React from 'react';

import $ from 'jquery'
import _ from 'lodash'

import CountryFilter from './CountryFilter';
import VersionFilter from './VersionFilter';


export default class SubHeader extends React.Component {

	constructor(props) {

		super(props);

	}

	render() {

		var alphaCountries = _.sortBy(this.props.countries, 'name');

		var _this = this

		var activeVersion = _.find(this.props.versions, function(v, index){
			return _this.props.activeVersion == v.title;
		})

		return (
			<div className="sub-header-wrapper">

				<div className="sub-header">

					<div className="sub-header-filter">

						<div className="dropdowns">

							<CountryFilter {...this.props} />

							<VersionFilter {...this.props} />

						</div>

					</div>

					<div className="sub-header-actions">

						<div className="actions">

							{ activeVersion ? <a href={`/${activeVersion.sourceDataPage}`} target="_blank">Source Data</a> : null }

						</div>

					</div>

				</div>

			</div>
		);
	}

}
