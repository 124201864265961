import Headroom from "headroom.js";

export default function() {
  if (!Modernizr.mobile) {
    const header = document.querySelector("header");

    const headroom = new Headroom(header, {
      offset: 0,
      classes: {
        initial: "visibility",
        pinned: "visibility-pinned",
        unpinned: "visibility-unpinned",
        top: "visibility-top",
        notTop: "visibility-not-top",
        bottom: "visibility-bottom",
        notBottom: "visibility-not-bottom"
      }
    });

    headroom.init();
  }
}
