import $ from "jquery";
import _ from "lodash";

import { EventEmitter } from "events";
import Dispatcher from "../dispatcher/Dispatcher";

import { ActionTypes } from "../constants/Constants";
const CHANGE_EVENT = "change";

export class DataStore extends EventEmitter {
  constructor(...args) {
    super(...args);
    this.resetData();
  }

  resetData() {
    this.data = {
      versions: [],
      indicators: [],
      countries: []
    };
    this.emitChange();
  }

  setData(data) {
    var countriesWithIndicators = data.countries;
    countriesWithIndicators = _.filter(countriesWithIndicators, function(c) {
      return "indicators" in c;
    });

    this.data = {
      versions: data.versions,
      indicators: data.indicators,
      allCountries: data.countries,
      countries: this.calculateRank(countriesWithIndicators, data.indicators)
    };

    this.emitChange();
  }

  getData() {
    return this.data;
  }

  emitChange() {
    this.emit(CHANGE_EVENT);
  }

  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
    this.setMaxListeners(1000);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  calculateRank(countries, indicators) {
    var debug = false;

    var indicatorCount = _.size(indicators);

    // Used to cache the ranks
    var indicatorRanks = [];

    _.each(indicators, function(indicator, indicatorKey) {
      indicatorRanks[indicator.id] = [];
    });

    // Used to cache the overrall ranks
    var overallRanks = [];

    _.each(countries, function(country, countryKey) {
      overallRanks[country.id] = 0;
    });

    // Loop through the countries
    _.map(countries, function(country, countryKey) {
      if (debug)
        console.log("Calculating rank of country " + country.name, country);

      // Loop through this country's indicators
      _.map(country.indicators, function(indicator, indicatorKey) {
        if (debug)
          console.log(
            "-- calculating rank of parent " + indicator.title,
            indicator
          );

        var sum = 0;

        // Loop through the child indicators
        _.map(indicator.children, function(childIndicator, childIndicatorKey) {
          if (debug)
            console.log(
              "--- calculating rank of child " + childIndicator.title,
              childIndicator
            );

          sum += parseFloat(childIndicator.rank);
        });

        // How many children with non-zero ranks
        var children = indicator.children;
        var nonZeroChildrenCount = _.size(children) - indicator.missingValues;

        // Calculate the new average rank of the parent indicator
        indicator.averageRank = parseFloat(sum / nonZeroChildrenCount).toFixed(
          6
        );

        if (debug)
          console.log("-- average parent rank is " + indicator.averageRank);

        // Cache this rank
        indicatorRanks[indicator.id].push(indicator.averageRank);
      });
    });

    _.each(indicatorRanks, function(indicatorRank, key) {
      indicatorRanks[key] = _.sortBy(indicatorRank);
    });

    // Loop through the countries and sort out the overall indicator rank
    _.map(countries, function(country, countryKey) {
      _.map(country.indicators, function(indicator, indicatorKey) {
        // Find the average rank in the sorted indicator list
        var rank =
          _.indexOf(indicatorRanks[indicator.id], indicator.averageRank) + 1;

        indicator.rank = parseInt(rank);

        // Add to rank sum
        country.rankSum += indicator.rank;

        // Add this to the related overall rank
        overallRanks[country.id] =
          parseInt(overallRanks[country.id]) + indicator.rank;

        // Check if this is the lowest rank
        if (indicator.rank > country.lowestRank) {
          country.lowestRank = indicator.rank;
        }

        // And add to a quick ranks array
        country.ranks[indicator.id] = indicator.rank;
      });

      // Calculate the mean
      country.rankMean = overallRanks[country.id] / indicatorCount;

      // Add to the overall ranks list
      overallRanks[country.id] = country.rankMean;
    });

    // Sort the overall rank
    var overallRanksSorted = _.sortBy(overallRanks);

    if (debug) console.log("Sort the overall ranks", overallRanksSorted);

    // Loop through the countries and sort out the overall indicator rank
    _.map(countries, function(country, countryKey) {
      var rank = _.indexOf(overallRanksSorted, country.rankMean) + 1;

      country.rank = rank;

      if (debug) console.log(`${country.name} ranked ${country.rank}`, country);
    });

    // Finally, sort countries by rank
    var rankedCountries = _.sortBy(countries, "rank");

    if (debug) console.log("Country list, sorted by rank", rankedCountries);

    // Finally check for ties
    // Need to check for ties as many times as there are ties
    var handleTiesCount = 0;

    var handleTies = function() {
      handleTiesCount++;

      var previousCountry = null;

      _.map(rankedCountries, function(country, countryKey) {
        if (previousCountry && country.rank == previousCountry.rank) {
          // we have a tie

          if (debug)
            console.log(
              `-- ${country.name} (lowest ${country.lowestRank}) tied with ${
                previousCountry.name
              } (lowest ${previousCountry.lowestRank})`,
              country,
              previousCountry
            );

          if (country.lowestRank > previousCountry.lowestRank) {
            country.rank += 1;
          } else {
            previousCountry.rank += 1;
          }

          if (debug) console.log(`${country.name} adjusted to ${country.rank}`);

          // Sort again if there was a tie
          rankedCountries = _.sortBy(countries, "rank");

          // Now start again
          handleTies();
        }

        previousCountry = country;
      });
    };

    handleTies();

    if (debug) console.log("Ranked countries", rankedCountries);

    return rankedCountries;
  }
}

let store = new DataStore();

Dispatcher.register(action => {
  switch (action.type) {
    case ActionTypes.LOAD_DATA:
      store.resetData();
      break;
    case ActionTypes.RECEIVE_DATA:
      store.setData(action.data);
      break;
    default:
  }
});

export default store;
