import React from 'react';

import $ from 'jquery'
import _ from 'lodash'

import ParentIndicator from './ParentIndicator';



export default class ParentIndicators extends React.Component {

	constructor(props) {

		super(props);

	}

	render() {

		return (

			<div className="indicators">

				{
					_.map(this.props.indicators, (indicator, index) => {
						return <ParentIndicator {...this.props}
							key={indicator.id}
							indicator={indicator} />
					})
				}

			</div>

		)
	}

}
