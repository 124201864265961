import React from 'react';

import $ from 'jquery'
import _ from 'lodash'



export default class Loading extends React.Component {

	constructor(props) {

		super(props);

	}

	render() {

		var rows = [];

		for (var i=0; i < 100; i++) {
			rows.push(
				<div className="row" key={`loading-row-${i}`}>
					<div className="ranks">
						<span className="roundel"></span>
					</div>
					<div className="name"></div>
					<div className="indicators">
						<div className="indicator"></div>
						<div className="indicator"></div>
						<div className="indicator"></div>
						<div className="indicator"></div>
						<div className="indicator"></div>
						<div className="indicator"></div>
						<div className="indicator"></div>
					</div>
				</div>
			);
		}

		return (

			<div className="index-loading">

				<div className="message">
					Loading Data
				</div>

				<div className="rows">

					{rows}

				</div>

			</div>

		)

	}

}
