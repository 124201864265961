export const ActionTypes = {
  // Data
  LOAD_DATA: 'LOAD_DATA',
  RECEIVE_DATA: 'RECEIVE_DATA',

  // Indicators
  ACTIVATE_INDICATOR: 'ACTIVATE_INDICATOR',
};

export default ActionTypes;
